<template>
<div class="statistics">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="statistics-cont">
    <Row :gutter="20" style="margin-bottom: 20px">
      <Col span="24">
        <div class="top">
          <p class="val">{{ train.name }}</p>
          <div>
            <span v-if="train.status!=='3'" class="previewResultTip">请在考试结束后，查看结果</span>
            <el-button :type="train.status==='3'?'primary':'info'" :disabled="train.status!=='3'" @click="viewExamResults()" size="small">查看考试结果</el-button>
          </div>
        </div>
      </Col>
    </Row>
    <Row :gutter="20">
      <Col span="24">
        <div class="top">
          <!-- <div class="tit">
            仪表盘
          </div> -->
          <div class="cont">
            <div class="itemfirst">
              <!-- <img src="../../../../assets/images/icon/peoples-two.png" /> -->
              <div>
                <p>参与人数</p>
                <p class="val">{{ joinUserCount || 0 }}</p>
              </div>
            </div>
            <div class="item">
              <!-- <img src="../../../../assets/images/icon/peoples.png" /> -->
              <div>
                <p>总知识点数</p>
                <p class="val">{{ train_data_node_count || 0 }}</p>
              </div>
            </div>
            <div class="item">
              <!-- <img src="../../../../assets/images/icon/peoples.png" /> -->
              <div>
                <p>总答题数</p>
                <p class="val">{{ have_finished_total || 0 }}</p>
              </div>
            </div>
            <div class="item">
              <!-- <img src="../../../../assets/images/icon/peoples.png" /> -->
              <div>
                <p>总答对数</p>
                <p class="val">{{ right_count_total || 0 }}</p>
              </div>
            </div>
          </div>
          <div class="cont">
            <div class="itemfirst">
              <!-- <img src="../../../../assets/images/icon/peoples-two.png" /> -->
              <div>
                <p>我的答题数</p>
                <p class="val">{{ have_finished || 0 }}</p>
              </div>
            </div>
            <div class="item">
              <!-- <img src="../../../../assets/images/icon/peoples-two.png" /> -->
              <div>
                <p>我的答对数</p>
                <p class="val">{{ rightCount || 0 }}</p>
              </div>
            </div>
            <div class="item">
              <!-- <img src="../../../../assets/images/icon/peoples-two.png" /> -->
              <div>
                <p>掌握的知识点</p>
                <p class="val">{{ nodeCount || 0 }}</p>
              </div>
            </div>
            <div class="item">
              <!-- <img src="../../../../assets/images/icon/peoples.png" /> -->
              <div>
                <p>未掌握的知识点</p>
                <p class="val">{{ wrongNodeCount || 0 }}</p>
              </div>
            </div>
          </div>
          <div class="cont" :v-if="train.type != '0' || train.type > 0">
            <div class="itemfirst">
              <!-- <img src="../../../../assets/images/icon/peoples-two.png" /> -->
              <div>
                <p>考试通过人数</p>
                <p class="val">{{ passUserCount || 0 }}</p>
              </div>
            </div>
          </div>
        </div>
        <Row :gutter="20">
          <Col span="12">
            <div class="active-num">
              <div class="active-num-top">
                <div class="tit">大家掌握的知识点分析</div>
              </div>
              <div id="chart1" class="num-chart"></div>
            </div>
          </Col>
          <Col span="12">
            <div class="active-num">
              <div class="active-num-top">
                <div class="tit">大家未掌握的知识点分析</div>
              </div>
              <div id="chart2" class="num-chart"></div>
            </div>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="12">
            <div class="active-num">
              <div class="active-num-top">
                <div class="tit">我掌握的知识点分析</div>
              </div>
              <div id="chart3" class="num-chart"></div>
            </div>
          </Col>
          <Col span="12">
            <div class="active-num">
              <div class="active-num-top">
                <div class="tit">我未掌握的知识点分析</div>
              </div>
              <div id="chart4" class="num-chart"></div>
            </div>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="12">
            <div class="active-num">
              <div class="active-num-top">
                <div class="tit">刷题排行</div>
              </div>
              <div class="table">
                <Table :columns="rankColumns" :data="ranklist"></Table>
              </div>
            </div>
          </Col>
          <Col span="12">
            <div class="active-num">
              <div class="active-num-top">
                <div class="tit">错题排行</div>
              </div>
              <div class="table">
                <Table :columns="wrongDataColumns" :data="wrong_data_list"></Table>
              </div>
            </div>
          </Col>

        </Row>
      </Col>
      <!-- <Col span="8">
        <div class="rank">刷题排行榜</div>
        <div class="table">
          <Table :columns="columns" :data="userStatList"></Table>
        </div>

      </Col> -->
    </Row>
  </div>

</div>
</template>

<script>
export default {
  name: "trainReportView",
  data(){
    return {
      rankColumns: [
        {
          title: '排名',
          type:'index',
          align:'center'
        },
        {
          title: '用户',
          align:'center',
          render: (h, para) => {
            return h('span', para.row.user.nickname+'('+para.row.user.id+')');
          }
        },
        {
          title: '答题量',
          align:'center',
          render: (h, para) => {
            return h('span', para.row.have_finished);
          }
        },
        {
          title: '答对数',
          align:'center',
          render: (h, para) => {
            return h('span', para.row.right_count);
          }
        },
        {
          title: '得分',
          align:'center',
          render: (h, para) => {
            return h('span', para.row.score);
          }
        },
      ],
      wrongDataColumns: [
        {
          title: '排名',
          type:'index',
          align:'center',
          width: 80,
        },
        {
          title: '题目',
          align:'center',
          render: (h, para) => {
            return h('span',{
              style:{
                'textAlign':'left',
                display:'block'
              }
            }, para.row.data.name+'('+para.row.data.id+')');
          }
        },
        // {
        //   title: '总答题量',
        //   render: (h, para) => {
        //     return h('span', para.row.data.answer_count);
        //   }
        // },
        // {
        //   title: '总答对数',
        //   render: (h, para) => {
        //     return h('span', para.row.data.right_count);
        //   }
        // },
        {
          title: '答错次数',
          align:'center',
          width: 120,
          render: (h, para) => {
            return h('span', para.row.count);
          }
        },
      ],
      train:{},
      joinUserCount : 0,
      passUserCount: 0,
      ispass : 0,
      ranklist : [],
      nodeCount:0,
      wrongNodeCount:0,
      nodelist:[],
      wrongNodeList:[],
      rightCount:0,
      have_finished:0,
      have_finished_total:0,
      right_count_total:0,
      train_data_node_count:0,
      wrong_data_list:[],
      mynodelist:[],
      mywrongNodeList:[],
    }
  },
  mounted(){
    //this.productId = this.$route.params.id;
    this.getData();
  },
  created(){
    this.trainUserId = this.$route.query.id;
    //this.getAnswerCard();
  },
  methods:{
    getData(){
      let params = {
        action:'practiceReport',
        train_user_id:this.trainUserId,
        only_wrong:this.onlyWrong ? '1' : '0'
      };
      this.answerCardLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.train = res.train;
        this.joinUserCount = res.train.join_user_count;
        this.passUserCount = res.train.pass_user_count
        this.ispass = res.train_user.is_pass;
        this.ranklist = res.train_user_rank_list;
        this.nodeCount = res.train_user_node_count;
        this.wrongNodeCount = res.train_user_wrong_node_count;
        this.nodelist = res.train_user_data_node_list;
        this.wrongNodeList = res.train_user_wrong_node_list;
        this.have_finished = res.train_user.have_finished;
        this.rightCount = res.train_user.right_count;
        this.have_finished_total = res.have_finished_total;
        this.right_count_total = res.right_count_total;
        this.train_data_node_count = res.train_data_node_count;
        this.wrong_data_list = res.wrong_data_list;
        this.mynodelist = res.my_train_user_data_node_list;
        this.mywrongNodeList = res.my_train_user_wrong_node_list;

        this.setOption1();
        this.setOption2();
        this.setOption3();
        this.setOption4();
      })
    },
    setOption1(){
      let chartDom = document.getElementById('chart1');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      if(this.nodelist && this.nodelist.length){
        this.nodelist.forEach((res)=>{
          names.push(res.name);
          counts.push(res.data_count);
        });
      }

      option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '个数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };
      console.log(option);

      option && myChart.setOption(option);
    },
    setOption2(){
      let chartDom = document.getElementById('chart2');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      if(this.wrongNodeList && this.wrongNodeList.length){
        this.wrongNodeList.forEach((res)=>{
          names.push(res.name);
          counts.push(res.data_count);
        });
      }

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          right:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '个数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);
    },
    setOption3(){
      let chartDom = document.getElementById('chart3');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      if(this.mynodelist && this.mynodelist.length){
        this.mynodelist.forEach((res)=>{
          names.push(res.name);
          counts.push(res.data_count);
        });
      }

      option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '个数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };
      console.log(option);

      option && myChart.setOption(option);
    },
    setOption4(){
      let chartDom = document.getElementById('chart4');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      if(this.mywrongNodeList && this.mywrongNodeList.length){
        this.mywrongNodeList.forEach((res)=>{
          names.push(res.name);
          counts.push(res.data_count);
        });
      }


      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          right:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '个数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);
    },
    // 查看考试结果
    viewExamResults(){
      this.$router.push({
        path:'/examine/trainingcamp/exam',
        query:{
          id:this.train.id,
          isResult:true,
          isSelf:true
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.statistics{
  margin: 20px;
  font-size: 14px;

  .statistics-cont{
    padding: 20px;
    background: #ffffff;
  }

  .tit{
    font-size: 18px;
    font-weight: bold;
  }

  .top{
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border:1px solid #dcdee2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cont{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .itemfirst{
          margin-left: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          >img{
            margin-right: 10px;
            width: 60px
          }
          >div{
            min-width: 100px;
            .val{
              margin-top: 5px;
              font-size: 20px;
              font-weight: bold;
            }
          }
        }

      .item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >img{
          margin-right: 10px;
          width: 60px
        }
        >div{
          min-width: 150px;
          .val{
            margin-top: 5px;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .previewResultTip{
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .active-num{
    margin-top: 20px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border:1px solid #dcdee2;

    .active-num-top{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tab{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border:1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;
        >p{
          padding: 0 5px;
          border-right: 1px solid #dcdee2;
        }
        >p:last-child{
          border-right:none;
        }
        >p.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }
    }

    .num-chart{
      width: 100%;
      height: 300px;
    }
  }
  .rank{
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .table{
    //height: 876px;
    overflow-y: auto;
  }
}
</style>
